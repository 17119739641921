/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}


//     <head>
// 	{/* Journal
// 	Journal Theme by https://jekyllthemes.io
// 	Premium + free Jekyll themes for your blog or website. */}
// 	<meta charset="utf-8">
// 	<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
// 	{/* <!-- Page Info --> */}
// 	<link rel="shortcut icon" href="/images/favicon.png">
// 	<title>Journal – A stunning personal blog Jekyll theme</title>
// 	<meta name="description" content="Journal is a stunning personal blog Jekyll theme with an image-focused design.">
// 	{/* <!-- Twitter Card --> */}
// 	<meta name="twitter:card" content="summary_large_image">
// 	<meta name="twitter:title" content="Journal – A stunning personal blog Jekyll theme">
// 	<meta name="twitter:description" content="Journal is a stunning personal blog Jekyll theme with an image-focused design.">
// 	<meta name="twitter:image:src" content="http://localhost:4000/images/demo/demo-landscape.jpg">
// 	{/* <!-- Facebook OpenGraph --> */}
// 	<meta property="og:title" content="Journal – A stunning personal blog Jekyll theme" />
// 	<meta property="og:description" content="Journal is a stunning personal blog Jekyll theme with an image-focused design." />
// 	<meta property="og:image" content="http://localhost:4000/images/demo/demo-landscape.jpg" />
// 	{/* <!-- Font Embed Code --> */}

// 	{/* <!-- Styles --> */}
// 	<meta name="viewport" content="width=device-width, initial-scale=1">
// 	{/* <!-- Icons --> */}
// 	<script defer src="https://use.fontawesome.com/releases/v5.1.1/js/solid.js" integrity="sha384-GXi56ipjsBwAe6v5X4xSrVNXGOmpdJYZEEh/0/GqJ3JTHsfDsF8v0YQvZCJYAiGu" crossorigin="anonymous"></script>
// 	<script defer src="https://use.fontawesome.com/releases/v5.1.1/js/brands.js" integrity="sha384-0inRy4HkP0hJ038ZyfQ4vLl+F4POKbqnaUB6ewmU4dWP0ki8Q27A0VFiVRIpscvL" crossorigin="anonymous"></script>
// 	<script defer src="https://use.fontawesome.com/releases/v5.1.1/js/fontawesome.js" integrity="sha384-NY6PHjYLP2f+gL3uaVfqUZImmw71ArL9+Roi9o+I4+RBqArA2CfW1sJ1wkABFfPe" crossorigin="anonymous"></script>
// 	{/* <!-- Custom Styles --> */}
// 	<style></style>
// 	{/* <!-- Analytics Code --> */}
// 	{/* <!-- Extra Header JS Code --> */}

// </head>




export default SEO
